import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./FeApp.css";
import styles from "./FeApp.module.css";

import { HomePage } from "./pages/HomePage";
import { TeamPage } from "./pages/TeamPage";
import { ContactPage } from "./pages/ContactPage";
import { DonateFoodPage } from "./pages/DonateFoodPage";
import { DonateMoneyPage } from "./pages/DonateMoneyPage";
import { FaqPage } from "./pages/FaqPage";
import { AchievementsPage } from "./pages/AchievementsPage";
import { HelpRequestPage } from "./pages/HelpRequestPage";
import { CookiesProvider } from "react-cookie";

import { OrganisationPage } from "./pages/OrganisationPage";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import { FeLanguage, FeResource } from "./fe-const";
import ErrorPage from "./pages/ErrorPage";
import { LangSwitcherPage } from "./components/langSwitcher/LangSwitcher";

const FeApp = () => {
  const router = createHashRouter([
    { path: FeResource.Root, element: <HomePage />, errorElement: <ErrorPage /> },
    { path: FeResource.Team, element: <TeamPage />, errorElement: <ErrorPage /> },
    { path: FeResource.Faq, element: <FaqPage />, errorElement: <ErrorPage /> },
    { path: FeResource.Contact, element: <ContactPage />, errorElement: <ErrorPage /> },
    { path: FeResource.RequestHelp, element: <HelpRequestPage />, errorElement: <ErrorPage /> },
    { path: FeResource.DonateMoney, element: <DonateMoneyPage />, errorElement: <ErrorPage /> },
    { path: FeResource.DonateSupplies, element: <DonateFoodPage />, errorElement: <ErrorPage /> },
    { path: FeResource.Achievements, element: <AchievementsPage />, errorElement: <ErrorPage /> },
    { path: FeResource.Organisation, element: <OrganisationPage />, errorElement: <ErrorPage /> },
    { path: FeResource.Ukrainian, element: <LangSwitcherPage lang={FeLanguage.Ukr} />, errorElement: <ErrorPage /> },
    { path: FeResource.English, element: <LangSwitcherPage lang={FeLanguage.Eng} />, errorElement: <ErrorPage /> },
    { path: FeResource.Swedish, element: <LangSwitcherPage lang={FeLanguage.Swe} />, errorElement: <ErrorPage /> },
  ]);

  return (
    <React.StrictMode>
      <CookiesProvider>
        <Container as="main" className={classNames(styles.pawsMain)} fluid>
          <RouterProvider router={router} />
        </Container>
      </CookiesProvider>
    </React.StrictMode>
  );
};

export default FeApp;
