import React from "react";
import { Header } from "../components/headerAndFooter/Header";
import { Footer } from "../components/headerAndFooter/Footer";
import { PawsLangStrings, useTranslate } from "../components/langSwitcher/LangSwitcher";
import { orgNr, stadgarSweUrl, stadgarUkrUrl, styrelsenValdFor } from "../fe-const";
import { Link } from "react-router-dom";
import { FeLanguage, FeResource } from "../fe-const";

const strings: PawsLangStrings = {
  [FeLanguage.Swe]: {
    "Organisation Information": "Information om organisationen",
    "text.orgReg": `Organisationen är en ideell förening och är registrerad i Sverige under nummer ${orgNr}.`,
    "text.theBoard": `Styrelsen vald för ${styrelsenValdFor}`,
    "see.team": "se team-sidan",
    "text.stadgarSwe": "Föreningens stadgar (svenska, original)",
    "text.stadgarUkr": "Föreningens stadgar (ukrainska, översättning)",
  },
  [FeLanguage.Ukr]: {
    "Organisation Information": "Інформація про організацію",
    "text.orgReg": `Організація є неприбутковою та благодійною, і зареєстрована в Швеції під номером ${orgNr}.`,
    "text.theBoard": `Правління об'єднання обране на ${styrelsenValdFor} рік`,
    "see.team": "див. сторінку команди",
    "text.stadgarSwe": "Статут об'єднання (шведською, оригінал)",
    "text.stadgarUkr": "Статут об'єднання (українською, переклад)",
  },
  [FeLanguage.Eng]: {
    "text.orgReg": `The organisation is a non-profit charity, and is registered in Sweden under the number ${orgNr}.`,
    "text.theBoard": `The board elected for ${styrelsenValdFor}`,
    "see.team": "see the team page",
    "text.stadgarSwe": "Bylaws of the organisation (Swedish, original)",
    "text.stadgarUkr": "Bylaws of the organisation (Ukrainian, translation)",
  },
};

const OrganisationPage = () => {
  const { translate } = useTranslate(strings);

  return (
    <>
      <Header currentPageId="organisation" />

      <h1>{translate("Organisation Information")}</h1>

      <p>{translate("text.orgReg")}</p>
      <p>
        {translate("text.theBoard")}: <Link to={FeResource.Team}>{translate("see.team")}</Link>
      </p>
      <p>
        <a target="_blank" rel="noreferrer" href={stadgarSweUrl}>
          {translate("text.stadgarSwe")}
        </a>
      </p>
      <p>
        <a target="_blank" rel="noreferrer" href={stadgarUkrUrl}>
          {translate("text.stadgarUkr")}
        </a>
      </p>

      <Footer />
    </>
  );
};

export { OrganisationPage };
