import React from "react";
import { Header } from "../components/headerAndFooter/Header";
import Email from "../images/contact/mail.png";
import Facebook from "../images/contact/fb.png";
import Instagram from "../images/contact/insta1.png";
import LinkedIn from "../images/contact/linkedin.png";
import Linktree from "../images/contact/linktree.png";
import styles from "../FeApp.module.css";
import classNames from "classnames";
import { Map } from "../components/main/Map";
import { Footer } from "../components/headerAndFooter/Footer";
import { PawsLangStrings, useTranslate } from "../components/langSwitcher/LangSwitcher";
import { PawsAddress, PawsOpeningHours } from "../components/prefabs";
import { pawsInfoEmail, pawsInstagram, pawsLinkedin, pawsLinktree } from "../fe-const";
import { Link } from "react-router-dom";
import { FeLanguage } from "../fe-const";

const strings: PawsLangStrings = {
  [FeLanguage.Swe]: {
    "Contact Paws of Peace": "Kontakta Paws of Peace",
    "Find Us Here": "Hitta oss här",
  },
  [FeLanguage.Ukr]: {
    "Contact Paws of Peace": "Зв'язатися з Paws of Peace",
    "Find Us Here": "Знайдіть нас тут",
  },
  [FeLanguage.Eng]: {},
};

const ContactPage = () => {
  const { translate } = useTranslate(strings);

  return (
    <>
      <Header currentPageId="contact" />

      <h1>{translate("Contact Paws of Peace")}</h1>

      <ul className="vertical-list list-unstyled">
        <li className="ms-3">
          <a href={`mailto:${pawsInfoEmail}`}>
            <img src={Email} className={classNames(styles.textInline, styles.contactIcon)} alt="E-mail" />
            <span>{pawsInfoEmail}</span>
          </a>
        </li>
        <li className="ms-3">
          <a href="https://www.facebook.com/pawsofpeace">
            <img src={Facebook} className={classNames(styles.textInline, styles.contactIcon)} alt="Facebook" />
            pawsofpeace
          </a>
        </li>
        <li className="ms-3">
          <a href={pawsInstagram}>
            <img
              className={classNames(styles.textInline, styles.contactIcon, "black-and-white")}
              src={Instagram}
              alt="Instagram"
            />
            @thepawsofpeace
          </a>
        </li>
        <li className="ms-3">
          <Link to={pawsLinkedin}>
            <img src={LinkedIn} className={classNames(styles.textInline, styles.contactIcon)} alt="Linkedin" />
            paws-of-peace
          </Link>
        </li>
        <li className="ms-3">
          <Link to={pawsLinktree}>
            <img src={Linktree} className={classNames(styles.textInline, styles.contactIcon)} alt="Linktree" />
            pawsofpeace
          </Link>
        </li>
      </ul>

      <h3>{translate("Find Us Here")}</h3>

      <p>
        <PawsAddress />
      </p>
      <p>
        <PawsOpeningHours />
      </p>

      <Map />

      <Footer />
    </>
  );
};

export { ContactPage };
