import React from "react";
import Email from "../../images/contact/mail.png";
import Instagram from "../../images/contact/insta1.png";
import LinkedIn from "../../images/contact/linkedin.png";
import Facebook from "../../images/contact/fb.png";
import styles from "../../FeApp.module.css";
import classNames from "classnames";
import { orgNr, pawsFacebook, pawsInfoEmail, pawsInstagram, pawsLinkedin } from "../../fe-const";
import { Link } from "react-router-dom";
import { PawsLangStrings, useTranslate } from "../langSwitcher/LangSwitcher";
import { FeLanguage } from "../../fe-const";

const strings: PawsLangStrings = {
  [FeLanguage.Swe]: {
    "text.copy": `© 2024 Paws of Peace. Organisationsnummer ${orgNr}`,
  },
  [FeLanguage.Ukr]: {
    "text.copy": `© 2024 Paws of Peace. Зарєстрований номер в Швеції ${orgNr}`,
  },
  [FeLanguage.Eng]: {
    "text.copy": `© 2024 Paws of Peace. Registered org nr in Sweden ${orgNr}`,
  },
};

export const Footer = () => {
  const { translate } = useTranslate(strings);

  return (
    <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top footer">
      <div className="col-md-8 d-flex align-items-center">
        <span className="mb-4 mb-md-0 text-body-secondary ">{translate("text.copy")}</span>
      </div>

      <ul className="nav col-md-4 justify-content-end align-items-baseline d-flex">
        <li className="ms-3">
          <a className="text-body-secondary" href={`mailto:${pawsInfoEmail}`}>
            <img src={Email} className={styles.footerInline} alt="Email" />
          </a>
        </li>
        <li className="ms-3">
          <Link className="text-body-secondary" to={pawsFacebook}>
            <img src={Facebook} className={styles.footerInline} alt="Facebook" />
          </Link>
        </li>
        <li className="ms-3">
          <Link className="text-body-secondary" to={pawsInstagram}>
            <img src={Instagram} className={classNames("black-and-white", styles.footerInline)} alt="Instagram" />
          </Link>
        </li>

        <li className="ms-3">
          <Link className="text-body-secondary" to={pawsLinkedin}>
            <img src={LinkedIn} className={styles.footerInline} alt="Linkedin" />
          </Link>
        </li>
      </ul>
    </footer>
  );
};
